import { Box, Button, Flex, Grid } from "@radix-ui/themes";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";
import CommonTitle from "components/common/title";
import { t } from "i18next";
import { GAP } from "lib/enums/radix-layout";
import { AppRoute } from "lib/enums/router";
import { useNavigate } from "react-router-dom";

const AssetClasses = () => {
  const navigate = useNavigate();

  const ITEMS: {
    heading: string;
    blurb: string;
    onClick: () => void;
  }[] = [
    {
      heading: "Real Estate Funds",
      blurb:
        "A key component to a diverse investment portfolio and a pillar to the Canadian investment landscape, bringing potential for long-term capital appreciation and stable income generation.",
      onClick: () => navigate(`/${AppRoute.RealEstateFunds}`),
    },
    {
      heading: "Mortgage Investments",
      blurb:
        "Well-diversified investment vehicles offering attractive yields and predictable cash flows, backed by the benefit of security.",
      onClick: () => navigate(`/${AppRoute.MortgageInvestments}`),
    },
    {
      heading: "Special Opportunities",
      blurb:
        "Limited Partnership opportunities presenting unique high-return prospects through exclusive access to private credit, private equity, and venture capital opportunities with high-potential companies and funds.",
      onClick: () => navigate(`/${AppRoute.SpecialOpportunities}`),
    },
  ];

  return (
    <>
      <CommonHeader align="center">Asset classes</CommonHeader>
      <Grid
        columns={{
          initial: "1",
          md: "3",
        }}
        gap={GAP.md}
      >
        {ITEMS.map((item, i) => (
          <Flex key={i} direction="column" gap={GAP.sm}>
            <CommonTitle>{item.heading}</CommonTitle>

            <CommonText>{item.blurb}</CommonText>

            <Box>
              <Button variant="outline" onClick={item.onClick}>
                {t("learn-more")}
              </Button>
            </Box>
          </Flex>
        ))}
      </Grid>
      <CommonSubHeader align="center">
        Why these asset classes? Leveraging a strategic view, BridgeMe
        Securities curates its investment offeringsbased on its potential to
        perform in current and future macroeconomic conditions through
        management experience. We are also keen on asset-specific
        characteristics that can navigate through up and down markets.
      </CommonSubHeader>
    </>
  );
};

export default AssetClasses;
