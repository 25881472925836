import Disclaimer from "components/layout/disclaimer";
import Footer from "components/layout/footer";
import Header from "components/layout/header";
import MainRouter from "components/main-router";
import env from "config";
import i18n from "translations/i18n";
import "./App.scss";

function App() {
  if (!env.debug_log) {
    console.debug = () => {};
  }

  i18n.init((error) => {
    if (error) {
      console.error(error);
    } else {
      console.debug(`i18next initialized w/ ${i18n.language}`);
    }
  });

  return (
    <div id="Main">
      <div id="Header">
        <Header />
      </div>
      <div id="Content">
        <MainRouter />
        <Disclaimer />
      </div>
      <div id="Footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
