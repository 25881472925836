import { ReactNode } from "react";

const CheckmarkList = (props: { children: ReactNode }) => (
  <ul
    style={{
      paddingInlineStart: "var(--space-5)",
      listStyleImage: "url('/images/check.svg')",
    }}
  >
    {props.children}
  </ul>
);

export default CheckmarkList;
