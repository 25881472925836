import { User } from "@auth0/auth0-react";
import { ConnectPayload } from "lib/interfaces/hubspot/connect";
import { ContactPayload } from "lib/interfaces/hubspot/contact";
import { BaseRESTService } from "services/_base-rest";

export class MainService extends BaseRESTService {
  private static instance: MainService;

  static getInstance(): MainService {
    if (!MainService.instance) {
      MainService.instance = new MainService();
    }

    return MainService.instance;
  }

  private constructor() {
    super({
      controller: "main",
    });
  }

  async uptime(): Promise<{ uptime: string; user: User }> {
    return await this.get({
      uri: "uptime",
    });
  }

  async postConnect(payload: ConnectPayload): Promise<boolean> {
    return await this.post(
      {
        uri: "connect",
      },
      payload
    );
  }

  async postContact(payload: ContactPayload): Promise<boolean> {
    return await this.post(
      {
        uri: "contact",
      },
      payload
    );
  }
}
