import { EnvConfig } from "lib/interfaces/env-config";

export const ENV_PROD: EnvConfig = {
  identifier: "prod",
  maintenance: false,
  strict: true,

  production: true,
  debug_log: true,

  self_url: "https://bridgeme.ca",
  server_url: "https://bridgeme.ca/api",
};
