import { Container } from "@radix-ui/themes";
import FlexWrapper from "components/common/flex-wrapper";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonTitle from "components/common/title";
import { t } from "i18next";
import { STYLE_COLOR } from "lib/enums/radix-layout";

const Statement = () => {
  return (
    <div
      id="Statement"
      style={{
        backgroundColor: STYLE_COLOR.ACCENT,
      }}
    >
      <Container size="3">
        <FlexWrapper>
          <CommonTitle align="center" style={{ color: STYLE_COLOR.HIGHLIGHT }}>
            {t("statement-header")}
          </CommonTitle>

          <CommonHeader align="center" className="text-white">
            {t("statement-subheader")}
          </CommonHeader>

          <CommonSubHeader className="text-white" align="center">
            {t("statement-blurb")}
          </CommonSubHeader>
        </FlexWrapper>
      </Container>
    </div>
  );
};

export default Statement;
