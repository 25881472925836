import { Container, Flex, Grid, Heading } from "@radix-ui/themes";
import FlexWrapper from "components/common/flex-wrapper";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";
import Connect from "components/connect";
import { SEPARATOR } from "components/landing";
import Opportunities from "components/opportunities";
import { FONT_SIZE, GAP, PAD_LG } from "lib/enums/radix-layout";

const RealEstateFunds = () => {
  return (
    <>
      <div className="soft-header">
        <Container size="3">
          <FlexWrapper>
            <Heading align="center" size={FONT_SIZE.title}>
              Real estate funds
            </Heading>

            <CommonText align="center">
              As a registered Exempt Market Dealer (EMD), BridgeMe Securities
              offers specialized access to a carefully curated selection of
              high-quality real estate opportunities across Canada, aligning
              with various investment goals and objectives.
            </CommonText>
          </FlexWrapper>
        </Container>
      </div>

      <Container size="3">
        <Grid
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
          columns={{
            initial: "1",
            md: "2",
          }}
          gap={GAP.md}
        >
          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/real-estate-funds/prod_realEstate_collage.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>
              Understanding Our Perspective on Real Estate Investing
            </CommonHeader>

            <CommonSubHeader>
              Real estate is a key component of a diversified investment
              strategy, particularly within private markets and alternative
              asset classes.
            </CommonSubHeader>

            <CommonText>
              Real estate investing provides an opportunity to generate
              consistent income and capital appreciation through its steady
              market growth, strong economic fundamentals, and relatively low
              volatility. Canadian real estate specifically attracts high demand
              from international investors and is based on strong regulatory
              frameworks that ensure market stability and investor confidence.
              Our talented team is dedicated to helping you understand these
              opportunities and make well informed investment decisions.
              BridgeMe Securities ensures that all offerings meet our high
              standards in due diligence.
            </CommonText>
          </Flex>
        </Grid>

        {SEPARATOR}

        <Grid
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
          columns={{
            initial: "1",
            md: "2",
          }}
          gap={GAP.md}
        >
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>Unique Real Estate Investment Facts</CommonHeader>

            <CommonSubHeader>
              Investing in real estate carries its own unique traits,
              particularly when different strategies and property types are
              considered.
            </CommonSubHeader>

            <CommonText>
              <p>
                Property types include residential, commercial, and mixed-use
                property types. Within these property types, there are:
              </p>

              <ul>
                <li>
                  Income-based strategies that generate a steady stream of
                  income through rent or lease payments from tenants;
                </li>
                <li>
                  Development strategies that involve constructing new real
                  estate or renovating existing properties to increase their
                  value, often requiring significant investment but offering the
                  potential for substantial capital returns; and
                </li>
                <li>
                  A hybrid strategy that involves both income and development.
                </li>
              </ul>

              <p>
                Additional factors such as geographical location, economic
                trends, and property management strategies will also play
                critical roles in the real estate fund's performance.
              </p>
            </CommonText>
          </Flex>

          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/real-estate-funds/collage_2.png"
            />
          </Flex>
        </Grid>

        {SEPARATOR}

        <FlexWrapper>
          <Opportunities />
        </FlexWrapper>
      </Container>

      <div className="soft-header">
        <Container size="2">
          <Connect />
        </Container>
      </div>
    </>
  );
};

export default RealEstateFunds;
