import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import env from "config";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  env: env.identifier,
};

export interface IServiceConfig {
  controller: string;
  headers?: HeadersInit;
}

interface AxiosRequest {
  uri?: string;
  params?: AxiosRequestConfig;
  headers?: AxiosRequestHeaders;
}

export interface IGenericResponse {
  message: string;
  error?: any;
}

export class BaseRESTService {
  private httpClient = axios.create({
    baseURL: env.server_url,
  });

  constructor(private config: IServiceConfig) {}

  async get(request: AxiosRequest = {}): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ""}`;

      const config = {
        params: request.params,
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
      };

      return this.httpClient
        .get(url, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async delete(request: AxiosRequest = {}): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ""}`;

      const config = {
        params: {
          ...request.params,
        },
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
      };

      return this.httpClient
        .delete(url, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async post(
    request: AxiosRequest = {},
    data: any,
    onProgress?: (event: ProgressEvent) => void
  ): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ""}`;
      const config = {
        params: request.params,
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
        onUploadProgress: (ev: ProgressEvent) => {
          if (onProgress) {
            onProgress(ev);
          }
        },
      } as any;

      return this.httpClient
        .post(url, data, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async put(request: AxiosRequest = {}, data: any): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ""}`;
      const config = {
        params: request.params,
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
      };

      return this.httpClient
        .put(url, data, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async patch(request: AxiosRequest = {}, data: any): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ""}`;
      const config = {
        params: request.params,
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
      };

      return this.httpClient
        .patch(url, data, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }
}
