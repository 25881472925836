import { Container, Separator } from "@radix-ui/themes";
import About from "./about";
import Establish from "./establish";
import Statement from "./statement";
import Technology from "./technology";

export const SEPARATOR = (
  <Container size="3">
    <Separator size="4" />
  </Container>
);

const Landing = () => {
  return (
    <>
      <Establish />
      <Statement />
      <About />
      {SEPARATOR}
      <Technology />
    </>
  );
};

export default Landing;
