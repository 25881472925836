import { Box, Container, Flex, Grid } from "@radix-ui/themes";
import FlexWrapper from "components/common/flex-wrapper";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";
import CommonTitle from "components/common/title";
import { SEPARATOR } from "components/landing";
import { GAP, PAD_LG } from "lib/enums/radix-layout";
import People from "./people";

const AboutUs = () => {
  return (
    <div id="AboutUs">
      <div className="soft-header">
        <Container size="2">
          <FlexWrapper>
            <CommonTitle>With us, there's no need to compromise</CommonTitle>

            <CommonSubHeader align="center">
              We leverage our financial expertise in the private markets to
              provide tailored strategies, maximizing growth and mitigating
              risks.
            </CommonSubHeader>
          </FlexWrapper>
        </Container>
      </div>

      <Container size="3">
        <Grid
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
          columns={{ initial: "1", md: "2" }}
          gap={GAP.md}
        >
          <Flex justify="center">
            <img
              alt="About Us"
              className="graphic-lg"
              src="/images/about-us/ourMission_collage.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>Our mission</CommonHeader>

            <CommonSubHeader>
              We are driven by core values of integrity, excellence, and
              accountability. We pride ourselves in our continuous efforts to
              deliver top-quality services, consistently building a brand that
              can be trusted.
            </CommonSubHeader>

            <CommonText>
              What does that mean? It means a better experience and better
              outcomes through being more committed to our work, providing a
              hand selected team of experts to meet the needs from beginning to
              end.
            </CommonText>
          </Flex>
        </Grid>

        {SEPARATOR}

        <Grid
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
          columns={{ initial: "1", md: "2" }}
          gap={GAP.md}
        >
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>Why us</CommonHeader>

            <CommonSubHeader>
              We prioritize <strong>collaboration</strong>,{" "}
              <strong>transparency</strong>, and <strong>trust</strong> in all
              of our operations. We recognize that navigating the private
              markets can be challenging, and we are prepared to help you
              realize the full potential of the opportunities in the space.
            </CommonSubHeader>

            <CommonText>
              Our team at BridgeMe Advisors leverages our extensive backgrounds
              to bridge this gap, ensuring that we curate well-planned and
              timely strategies. This builds on our commitment to enhancing the
              accessibility of high-quality opportunities in the private
              markets.
            </CommonText>
          </Flex>

          <Flex justify="center">
            <img
              alt="About Us"
              className="graphic-lg"
              src="/images/about-us/whyUs_collage.png"
            />
          </Flex>
        </Grid>

        {SEPARATOR}

        <Box pt={PAD_LG.pv} pb={PAD_LG.pv} pl={PAD_LG.ph} pr={PAD_LG.ph}>
          <People />
        </Box>
      </Container>
    </div>
  );
};

export default AboutUs;
