import { Box, Container, Flex, Grid, Link } from "@radix-ui/themes";
import CollageWrapper from "components/common/collage-wrapper";
import FlexWrapper from "components/common/flex-wrapper";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";
import CommonTitle from "components/common/title";
import { showToast } from "components/common/toast-listener";
import { GAP } from "lib/enums/radix-layout";
import { EMAILS } from "lib/enums/urls";
import ContactForm from "./form";

const TORONTO_ADDRESS_PARTS = [
  "Richmond-Adelaide Centre",
  "120 Adelaide St W, Suite 2500,",
  "Toronto, ON M5H 1T1, Canada",
];

const Contact = () => {
  const blurb = (
    <Flex direction="column" gap={GAP.sm}>
      <CommonSubHeader>Have a question?</CommonSubHeader>

      <CommonText>
        Feel free to use this form to contact us and a member of our team will
        get back to you shortly. We are looking forward to hearing from you and
        will be pleased to help.
      </CommonText>
    </Flex>
  );

  return (
    <Flex direction="column">
      <div className="soft-header">
        <Container size="2">
          <FlexWrapper>
            <CommonTitle align="center">Connect with us</CommonTitle>

            <Grid
              gap={GAP.md}
              columns={{
                initial: "1",
                md: "2",
              }}
            >
              <Box className="show-lt-md">{blurb}</Box>

              <ContactForm />

              <Box className="show-gt-md">{blurb}</Box>
            </Grid>
          </FlexWrapper>
        </Container>
      </div>

      <Container size="3">
        <CollageWrapper>
          <Flex justify="center">
            <img
              className="show-gt-md graphic-lg"
              src="/images/contact/collage_1.png"
            />
            <img
              className="show-lt-md graphic-lg"
              src="/images/contact/collage_2.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>Contact us</CommonHeader>

            <CommonSubHeader>Toronto</CommonSubHeader>

            <CommonText
              className="clickable"
              onClick={() => {
                navigator.clipboard.writeText(TORONTO_ADDRESS_PARTS.join("\n"));
                showToast({
                  title: "Success",
                  description: "Copied to clipboard!",
                });
              }}
            >
              {TORONTO_ADDRESS_PARTS[0]}
              <br />
              {TORONTO_ADDRESS_PARTS[1]}
              <br />
              {TORONTO_ADDRESS_PARTS[2]}
            </CommonText>

            <CommonText size="5">
              <Link href={`mailto:${EMAILS.SUPPORT}`}>{EMAILS.SUPPORT}</Link>
            </CommonText>
          </Flex>
        </CollageWrapper>
      </Container>
    </Flex>
  );
};

export default Contact;
