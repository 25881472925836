export enum AppRoute {
  Root = "",
  About = "about",
  Contact = "contact",
  InstitutionServices = "institution-services",
  InvestorServices = "investor-services",
  MortgageInvestments = "mortgage-investments",
  Privacy = "privacy",
  RealEstateFunds = "real-estate-funds",
  Relationship = "relationship",
  SpecialOpportunities = "special-opportunities",
  Terms = "terms",
}
