import { Container, Flex } from "@radix-ui/themes";
import CollageWrapper from "components/common/collage-wrapper";
import CommonHeader from "components/common/header";
import CommonText from "components/common/text";
import { t } from "i18next";
import { GAP } from "lib/enums/radix-layout";

const Technology = () => {
  return (
    <Container id="Technology" size="3">
      <CollageWrapper>
        <Flex direction="column" gap={GAP.sm}>
          <CommonHeader>{t("tech-header")}</CommonHeader>

          <CommonText>
            <p>{t("tech-blurb-1")}</p>
            <p>{t("tech-blurb-2")}</p>
          </CommonText>
        </Flex>

        <Flex justify="center">
          <img
            alt="Technology"
            className="graphic-lg"
            src="/images/technology/collage.png"
          />
        </Flex>
      </CollageWrapper>
    </Container>
  );
};

export default Technology;
