import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { Box, Button, Flex, Text, TextArea, TextField } from "@radix-ui/themes";
import ReCAPTCHAWrapper from "components/common/recaptcha";
import { showToast } from "components/common/toast-listener";
import env from "config";
import { t } from "i18next";
import { GAP } from "lib/enums/radix-layout";
import { ConnectPayload } from "lib/interfaces/hubspot/connect";
import { useState } from "react";
import {
  CheckboxConfig,
  TextAreaConfig,
  TextFieldConfig,
} from "react-interfaces/forms";
import { MainService } from "services/main";

const DISABLE_AFTER_SUBMISSION = env.identifier !== "local";

class FormHelper {
  private static renderTextLabel = (i18Key: string) => (
    <Text as="label" mb="2" style={{ display: "block" }}>
      {t(i18Key)}
    </Text>
  );

  static renderTextField = (config: TextFieldConfig) => {
    return (
      <Box width="100%">
        {config.label && this.renderTextLabel(config.label)}
        <TextField.Root>
          {config.slot && <TextField.Slot>{config.slot}</TextField.Slot>}
          <TextField.Input
            disabled={config.disabled}
            placeholder={config.placeholder ? t(config.placeholder) : undefined}
            value={config.defaultValue}
            onChange={config.onChange}
          />
        </TextField.Root>
      </Box>
    );
  };

  static renderTextArea = (config: TextAreaConfig) => {
    return (
      <Box width="100%">
        {config.label && this.renderTextLabel(config.label)}
        <TextArea
          disabled={config.disabled}
          placeholder={config.placeholder ? t(config.placeholder) : undefined}
          value={config.defaultValue}
          onChange={config.onChange}
        />
      </Box>
    );
  };

  static renderCheckbox = (config: CheckboxConfig) => {
    return (
      <Flex direction="row" gap={GAP.xs}>
        <Checkbox.Root
          className="CheckboxRoot"
          disabled={config.disabled}
          defaultChecked={config.defaultValue}
          onCheckedChange={(checked) => config.onChange(checked === true)}
        >
          <Checkbox.Indicator className="CheckboxIndicator">
            <CheckIcon />
          </Checkbox.Indicator>
        </Checkbox.Root>
        {config.label && this.renderTextLabel(config.label)}
      </Flex>
    );
  };
}

const validateContact = (value: ConnectPayload): string | undefined => {
  if (!value.firstName?.trim()) {
    return "First name";
  }

  if (!value.lastName?.trim()) {
    return "Last name";
  }

  if (!value.email?.trim()) {
    return "Email";
  }

  if (!value.consentToProcess) {
    return "Consent to process data";
  }

  if (!value.consentToComms) {
    return "Consent to receive communications";
  }
};

const DEFAULT_CONTACT: ConnectPayload = {
  firstName: "",
  lastName: "",
  email: "",
  consentToComms: false,
  consentToProcess: false,
};

const ConnectForm = () => {
  // don't require recaptcha for non-prod deployments
  const [passedReCAPTCHA, setPassedReCAPTCHA] = useState<boolean>(
    !env.production
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [model, setModel] = useState<ConnectPayload>(DEFAULT_CONTACT);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const disabled = loading || (DISABLE_AFTER_SUBMISSION && submitted);

  return (
    <Flex direction="column" gap={GAP.sm}>
      {FormHelper.renderTextField({
        label: "First name",
        disabled: disabled,
        onChange: (e) => setModel({ ...model, firstName: e.target.value }),
      })}

      {FormHelper.renderTextField({
        label: "Last name",
        disabled: disabled,
        onChange: (e) => setModel({ ...model, lastName: e.target.value }),
      })}

      {FormHelper.renderTextField({
        label: "Email address",
        disabled: disabled,
        onChange: (e) => setModel({ ...model, email: e.target.value }),
      })}

      {FormHelper.renderCheckbox({
        label:
          "I agree to allow BridgeMe to store and process my personal data.",
        disabled: disabled,
        onChange: (value) => setModel({ ...model, consentToProcess: value }),
      })}

      {FormHelper.renderCheckbox({
        label:
          'By checking this box, you consent to receiving marketing emails and other electronic communications from BridgeMe Securities Inc. You can unsubscribe at any time by clicking the "Unsubscribe" link in the emails.',
        disabled: disabled,
        onChange: (value) => setModel({ ...model, consentToComms: value }),
      })}

      <Flex direction="column" gap="2">
        {!submitted && (
          <ReCAPTCHAWrapper
            onChange={(token) => {
              if (!passedReCAPTCHA && !!token) {
                setPassedReCAPTCHA(true);
              }
            }}
          />
        )}

        <div>
          <Button
            disabled={disabled}
            onClick={async () => {
              if (env.production && !passedReCAPTCHA) {
                showToast({
                  title: "Invalid Submission",
                  description: "Please complete the reCAPTCHA challenge first.",
                });
                return;
              }

              const error = validateContact(model);

              if (error) {
                showToast({
                  title: "Invalid Submission",
                  description: `${error} is required.`,
                });
                return;
              }

              setLoading(true);

              const success = await MainService.getInstance()
                .postConnect(model)
                .finally(() => setLoading(false));

              showToast({
                title: success ? "Success" : "Error",
                description: success
                  ? `We've received your submission!`
                  : "There was a problem with your submission.",
              });

              if (success) {
                setSubmitted(true);
              }
            }}
          >
            Submit
          </Button>
        </div>
      </Flex>
    </Flex>
  );
};

export default ConnectForm;
