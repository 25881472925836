import { Container, Flex } from "@radix-ui/themes";
import CheckmarkList from "components/common/checkmark-list";
import CollageWrapper from "components/common/collage-wrapper";
import FlexWrapper from "components/common/flex-wrapper";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";
import CommonTitle from "components/common/title";
import Connect from "components/connect";
import { SEPARATOR } from "components/landing";
import { GAP } from "lib/enums/radix-layout";

const InstitutionServices = () => {
  return (
    <>
      <div className="soft-header">
        <Container size="3">
          <FlexWrapper>
            <CommonTitle align="center">Institutional services</CommonTitle>

            <CommonText align="center">
              BridgeMe Advisors offers bespoke strategies to our clients to
              optimize capital raising results that issuers demand in the
              current financing and regulatory environment.
            </CommonText>
          </FlexWrapper>
        </Container>
      </div>

      <Container size="3">
        <CollageWrapper>
          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/institution-services/emdTech_collage.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>Capital Raising Technology Services</CommonHeader>

            <CommonSubHeader>
              BridgeMe Advisors has the capability to provide a wide range of
              customized white label capital raise services to FinTech companies
              across Canada to help drive success to the companies.
            </CommonSubHeader>

            <CommonText>
              Our approach to developing custom solutions for each client
              reflects our commitment to enhancing accessibility in the private
              markets. BridgeMe Advisors' seasoned team leverages technical
              knowhow to create integrated, tailored solutions to fit each
              client's specific goals and market conditions.
            </CommonText>
          </Flex>
        </CollageWrapper>

        {SEPARATOR}

        <CollageWrapper>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>
              Experienced technology partners on your side.
            </CommonHeader>

            <CheckmarkList>
              <li>Custom technology solutions to meet your business needs.</li>
              <li>
                Integrated solutions to address your regulatory and compliance
                requirements.
              </li>
              <li>Dedicated support teams for client servicing.</li>
            </CheckmarkList>
          </Flex>

          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/institution-services/technology_collage.png"
            />
          </Flex>
        </CollageWrapper>

        {SEPARATOR}

        <CollageWrapper>
          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/institution-services/fundraisingCapital_collage.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>Fundraising Capital</CommonHeader>

            <CommonSubHeader>
              Our team collaborates with issuers across various sectors and
              stages to facilitate capital investment needs.
            </CommonSubHeader>

            <CommonText>
              Collaborating with our clients, we facilitate specialized
              placement agent services for efficient capital raising. BridgeMe
              Advisors provides a comprehensive suite of debt and equity
              financing options, emphasizing the unique benefits and business
              impacts of each solution. Our expertise fosters a long-term
              relationship with our clients, ensuring optimal investor alignment
              to support the needs of our clients' specific growth objectives
              and capital needs.
            </CommonText>
          </Flex>
        </CollageWrapper>

        {SEPARATOR}

        <CollageWrapper>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>
              Identifying the appropriate capital raise for you.
            </CommonHeader>

            <CheckmarkList>
              <li>Deep knowledge in debt and equity capital raising</li>
              <li>Industry partners in Canada and the United States</li>
              <li>Flexible a-la-carte fundraising solutions</li>
            </CheckmarkList>
          </Flex>

          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/institution-services/identifying_collage.png"
            />
          </Flex>
        </CollageWrapper>
      </Container>

      <div className="soft-header">
        <Container size="2">
          <Connect
            children={
              <>
                Curious to learn more about our capital raising strategies at
                BridgeMe Advisors? Contact us through this form and one of our
                team members will get in touch.
              </>
            }
          />
        </Container>
      </div>
    </>
  );
};

export default InstitutionServices;
