import { Heading } from "@radix-ui/themes";
import { FONT_SIZE } from "lib/enums/radix-layout";
import { ITextProps } from "lib/interfaces/layout";

const CommonHeader = (props: ITextProps) => (
  <Heading
    className={props.className}
    style={props.style}
    weight={props.weight ?? "light"}
    color={props.color ?? "indigo"}
    size={props.size ?? FONT_SIZE.header}
    align={props.align}
    onClick={props.onClick}
  >
    {props.children}
  </Heading>
);

export default CommonHeader;
