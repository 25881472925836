import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";

const Opportunities = () => {
  return (
    <>
      <CommonHeader align="center">
        Opportunities and How to Get Started
      </CommonHeader>

      <CommonSubHeader align="center">
        To invest or learn more about our opportunities, please contact us
        through the form below. Our team is ready to guide you through the
        process and provide you with the information you need to get started.
      </CommonSubHeader>
    </>
  );
};

export default Opportunities;
