import { Cross1Icon } from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import { Button } from "@radix-ui/themes";
import { t } from "i18next";
import React from "react";

const TOAST_KEY = "bm_toast";
const C_NAME = "ToastWrapper";

export interface ToastConfig {
  title: string;
  description: string;

  actions?: {
    i18Key: string;
    onClick: () => void;
  }[];
}

export const showToast = (config: ToastConfig) => {
  document.dispatchEvent(
    new CustomEvent(TOAST_KEY, {
      detail: config,
    })
  );
};

interface Props {
  position: "top-right" | "top-left" | "bottom-right" | "bottom-left";

  duration?: number;
  hideClose?: boolean;
}

interface State {
  open: boolean;
  config?: ToastConfig;
}

/** should only ever have one of these per app */
export class ToastListener extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleToast = this.handleToast.bind(this);
  }

  componentDidMount(): void {
    document.addEventListener(TOAST_KEY, this.handleToast);
  }

  componentWillUnmount(): void {
    document.removeEventListener(TOAST_KEY, this.handleToast);
  }

  private handleToast(event: Event) {
    try {
      const data: ToastConfig = {
        ...(event as CustomEvent).detail,
        triggered: new Date(),
      };

      this.setState({
        open: true,
        config: data,
      });
    } catch (e) {
      console.error(`${C_NAME} caught an error in handleToast`, e);
    }
  }

  render(): React.ReactNode {
    const config = this.state.config;

    return (
      <>
        <Toast.Viewport className={`ToastViewport ${this.props.position}`} />
        <Toast.Root
          open={this.state.open}
          onOpenChange={(open) => this.setState({ open: open })}
          duration={this.props.duration}
          className="ToastRoot"
        >
          <Toast.Title className="ToastTitle">{config?.title}</Toast.Title>

          <Toast.Description className="ToastDescription">
            {config?.description}
          </Toast.Description>

          {config?.actions?.map((a, i) => (
            <Toast.Action
              key={`toast-action-${i}`}
              className="ToastAction"
              altText="Close"
              asChild
            >
              <Button onClick={() => a.onClick()}>{t(a.i18Key)}</Button>
            </Toast.Action>
          ))}

          {!this.props.hideClose && (
            <Toast.Close aria-label="Close" asChild>
              <Cross1Icon />
            </Toast.Close>
          )}
        </Toast.Root>
      </>
    );
  }
}
