import { EnvConfig } from "lib/interfaces/env-config";

export const ENV_DEMO: EnvConfig = {
  identifier: "demo",
  maintenance: false,
  strict: true,

  production: true,
  debug_log: false,

  self_url: "https://demo.bridgeme.ca",
  server_url: "https://demo.bridgeme.ca/api",
};
