import { Container, Flex } from "@radix-ui/themes";
import CollageWrapper from "components/common/collage-wrapper";
import FlexWrapper from "components/common/flex-wrapper";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";
import CommonTitle from "components/common/title";
import Connect from "components/connect";
import { SEPARATOR } from "components/landing";
import { GAP } from "lib/enums/radix-layout";
import AssetClasses from "./asset-classes";
import RiskManagement from "./risk-management";

const InvestorServices = () => {
  return (
    <>
      <div className="soft-header">
        <Container size="3">
          <FlexWrapper>
            <CommonTitle align="center">Investment products</CommonTitle>

            <CommonHeader align="center">
              BridgeMe Securities navigates the private markets
              <br />
              with a strategic and timely approach.
            </CommonHeader>

            <img src="/images/investor-services/steps.svg" />
          </FlexWrapper>
        </Container>
      </div>

      <Container size="3">
        <CollageWrapper>
          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/investor-services/approach_collage.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>Approach</CommonHeader>

            <CommonSubHeader>
              Our approach is simple, and that is to present highly credible,
              timely investments that are selected by our extensive due
              diligence process.
            </CommonSubHeader>

            <CommonText>
              At BridgeMe Securities, our team is committed to delivering the
              most optimal opportunities for our investors. We curate our
              investment offerings to tap into timely market conditions and
              emerging opportunities. Adapting to changing market conditions and
              potential growth sectors, we ensure that our investors receive the
              benefit of an agile and proactive dealer. We carefully curate a
              selection of strategic offerings, ranging in risk-return profiles.
              We emphasize transparency and collaboration to ensure your
              investments align with your objectives.
            </CommonText>
          </Flex>
        </CollageWrapper>

        {SEPARATOR}

        <FlexWrapper>
          <AssetClasses />
        </FlexWrapper>

        {SEPARATOR}

        <FlexWrapper>
          <RiskManagement />
        </FlexWrapper>
      </Container>

      <div className="soft-header">
        <Container size="2">
          <Connect />
        </Container>
      </div>
    </>
  );
};

export default InvestorServices;
