import { Box, Button, Container, Flex } from "@radix-ui/themes";
import CollageWrapper from "components/common/collage-wrapper";
import CommonHeader from "components/common/header";
import CommonText from "components/common/text";
import { t } from "i18next";
import { GAP } from "lib/enums/radix-layout";
import { AppRoute } from "lib/enums/router";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  return (
    <Container id="About" size="3">
      <CollageWrapper>
        <Flex justify="center">
          <img
            alt="About Us"
            className="graphic-lg"
            src="/images/landing/about-us.png"
          />
        </Flex>
        <Flex direction="column" gap={GAP.sm}>
          <CommonHeader>{t("about-header")}</CommonHeader>

          <CommonText>
            <p>{t("about-blurb-1")}</p>
            <p>{t("about-blurb-2")}</p>
          </CommonText>

          <Box>
            <Button onClick={() => navigate(`/${AppRoute.About}`)}>
              {t("learn-more")}
            </Button>
          </Box>
        </Flex>
      </CollageWrapper>
    </Container>
  );
};

export default About;
