import { Flex } from "@radix-ui/themes";
import { GAP, PAD_LG } from "lib/enums/radix-layout";
import { ReactNode } from "react";

const FlexWrapper = (props: { children: ReactNode }) => (
  <Flex
    pt={PAD_LG.pv}
    pb={PAD_LG.pv}
    pl={PAD_LG.ph}
    pr={PAD_LG.ph}
    direction="column"
    gap={GAP.sm}
  >
    {props.children}
  </Flex>
);

export default FlexWrapper;
