import { Container, Flex } from "@radix-ui/themes";
import CollageWrapper from "components/common/collage-wrapper";
import FlexWrapper from "components/common/flex-wrapper";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";
import CommonTitle from "components/common/title";
import Connect from "components/connect";
import { SEPARATOR } from "components/landing";
import Opportunities from "components/opportunities";
import { GAP } from "lib/enums/radix-layout";

const MortgageInvestments = () => {
  return (
    <>
      <div className="soft-header">
        <Container size="3">
          <FlexWrapper>
            <CommonTitle align="center">Mortgage investments</CommonTitle>

            <CommonText align="center">
              BridgeMe Securities provides access to a selected range of
              mortgage investment opportunities across Canada. As a registered
              Exempt Market Dealer (EMD), we offer high-quality mortgage
              investments that cater to various investment goals and risk
              profile.
            </CommonText>
          </FlexWrapper>
        </Container>
      </div>

      <Container size="3">
        <CollageWrapper>
          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/mortgage-investments/collage_1.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>
              Understanding Our Approach to Mortgage Investing
            </CommonHeader>

            <CommonSubHeader>
              Mortgage investments represent a crucial component of a
              well-diversified investment strategy and a strong fixed income
              option.
            </CommonSubHeader>

            <CommonText>
              Mortgage investments aim to provide investors with stable and
              predictable income. Our focus is to provide investors with
              opportunities to diversify their portfolios through low- to
              higher-risk mortgage investments. The risk factor will determine
              the potential returns for the mortgage investments. For example,
              prime risk will likely result in lower returns and higher risk
              could yield higher returns. Our knowledgeable team is here to help
              you understand the complexities of mortgage investments and assist
              you with making informed investment decisions. BridgeMe Securities
              ensures that all offerings meet our high standards.
            </CommonText>
          </Flex>
        </CollageWrapper>

        {SEPARATOR}

        <CollageWrapper>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>Unique Factors to Mortgage Investing</CommonHeader>

            <CommonSubHeader>
              <p>
                Investing in mortgages presents unique considerations involving
                the hierarchy of different types of mortgages that take priority
                in defaults:
              </p>
              <ul>
                <li>First Mortgages, which carry a lower risk profile</li>
                <li>
                  Second Mortgages, which provide higher returns in echange for
                  a subordinated position; and
                </li>
                <li>
                  Syndicated Mortgages, which involve a pooled fund of mortgages
                  for larger projects often varying in risk and return.
                </li>
              </ul>
            </CommonSubHeader>

            <CommonText>
              Each type of mortgage will impact the risk and return profile of
              the investment opportunity. To meet investment targets, it will
              require a thorough assessment of property value, borrower
              reliability, market conditions, and other diligence requirements.
              This could include factors such as the borrower's credit risk,
              income stability, and overall financial health, which are critical
              in determining their ability to repay the mortgage and is directly
              associated with the risk of the investment.
            </CommonText>
          </Flex>

          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/mortgage-investments/collage_2.png"
            />
          </Flex>
        </CollageWrapper>

        {SEPARATOR}

        <FlexWrapper>
          <Opportunities />
        </FlexWrapper>
      </Container>

      <div className="soft-header">
        <Container size="2">
          <Connect />
        </Container>
      </div>
    </>
  );
};

export default MortgageInvestments;
