import { Text } from "@radix-ui/themes";
import { FONT_SIZE } from "lib/enums/radix-layout";
import { ITextProps } from "lib/interfaces/layout";

const CommonSubText = (props: ITextProps) => (
  <Text
    className={props.className}
    style={props.style}
    weight={props.weight}
    color={props.color}
    size={props.size ?? FONT_SIZE.subtext}
    align={props.align ? "center" : undefined}
    onClick={props.onClick}
  >
    {props.children}
  </Text>
);

export default CommonSubText;
