import { Box, Container, Flex, Link } from "@radix-ui/themes";
import CommonSubText from "components/common/sub-text";
import { GAP, PAD_SM } from "lib/enums/radix-layout";
import { URLS } from "lib/enums/urls";

interface NavButton {
  alt: string;
  imgPath: string;
  onClick: () => void;
}

const ICON_BUTTONS: NavButton[] = [
  {
    alt: "linkedin",
    imgPath: "/images/linkedin.svg",
    onClick: () => window.open(URLS.LINKEDIN),
  },
];

const Footer = () => {
  const renderButton = (b: NavButton) => (
    <Box key={`header-${b.imgPath}`}>
      <Link onClick={() => b.onClick()}>
        <img alt={b.alt} src={b.imgPath} />
      </Link>
    </Box>
  );

  return (
    <Container
      size="3"
      style={{
        backgroundColor: "#000a23",
      }}
    >
      <Flex
        pt="4"
        pb="4"
        pl={PAD_SM.ph}
        pr={PAD_SM.ph}
        gap={GAP.sm}
        justify="end"
      >
        <CommonSubText className="text-white">©BridgeMe</CommonSubText>
        {ICON_BUTTONS.map((b) => renderButton(b))}
      </Flex>
    </Container>
  );
};

export default Footer;
