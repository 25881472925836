export type RxPadding =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9";

interface PaddingDef {
  initial: RxPadding;
  md?: RxPadding;
}

export const PAD_LG: {
  pv: PaddingDef;
  ph: PaddingDef;
} = {
  pv: {
    initial: "8",
    md: "9",
  },
  ph: {
    initial: "6",
  },
};

export const PAD_SM: {
  pv: PaddingDef;
  ph: PaddingDef;
} = {
  pv: {
    initial: "8",
  },
  ph: {
    initial: "6",
  },
};

export const GAP: {
  xs: PaddingDef;
  sm: PaddingDef;
  md: PaddingDef;
  lg: PaddingDef;
} = {
  xs: {
    initial: "4",
  },
  sm: {
    initial: "4",
    md: "6",
  },
  md: {
    initial: "6",
    md: "8",
  },
  lg: {
    initial: "8",
    md: "9",
  },
};

export type RxTextSize = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

export interface RxTextSizeDef {
  initial: RxTextSize;
  md?: RxTextSize;
}

export const FONT_SIZE: {
  title: RxTextSizeDef;
  header: RxTextSizeDef;
  subheader: RxTextSizeDef;
  text: RxTextSizeDef;
  subtext: RxTextSizeDef;
} = {
  title: {
    initial: "9",
    md: "8",
  },
  header: {
    initial: "8",
    md: "7",
  },
  subheader: {
    initial: "6",
    md: "5",
  },
  text: {
    initial: "4",
    md: "3",
  },
  subtext: {
    initial: "3",
    md: "2",
  },
};

export type RxFlexDirection =
  | "column"
  | "column-reverse"
  | "row"
  | "row-reverse";

export const FLEX_DIR_MD: { initial: RxFlexDirection; md: RxFlexDirection } = {
  initial: "column",
  md: "row",
};

export const FLEX_DIR_MD_REVERSE: {
  initial: RxFlexDirection;
  md: RxFlexDirection;
} = {
  initial: "column-reverse",
  md: "row",
};

export const FLEX_DIR_SM: { initial: RxFlexDirection; sm: RxFlexDirection } = {
  initial: "column",
  sm: "row",
};

export const STYLE_COLOR = {
  ACCENT: "var(--accent-9)",
  HIGHLIGHT: "var(--yellow-a9)",
};

export type RxHeaderWeight = "light" | "regular" | "medium" | "bold";

export type RxTextAlign = "left" | "center" | "right";

export type RxColor =
  | "ruby"
  | "tomato"
  | "red"
  | "crimson"
  | "pink"
  | "plum"
  | "purple"
  | "violet"
  | "iris"
  | "indigo"
  | "blue"
  | "cyan"
  | "teal"
  | "jade"
  | "green"
  | "grass"
  | "brown"
  | "orange"
  | "sky"
  | "mint"
  | "lime"
  | "yellow"
  | "amber"
  | "gold"
  | "bronze"
  | "gray";
