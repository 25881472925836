import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";

const RiskManagement = () => {
  return (
    <>
      <CommonHeader align="center">Risk management</CommonHeader>

      <CommonSubHeader align="center">
        BridgeMe Securities is supported by a team of seasoned professionals
        with decades of experience in financial planningand analysis, liquidity
        management, and risk management. Our team's collective expertise enables
        us todeliver exceptional services to meet the unique needs and targets
        of our investors.
      </CommonSubHeader>

      <CommonText align="center">
        BridgeMe Securities Inc. is a registered Exempt Market Dealer (EMD) in
        Alberta, British Columbia, Manitoba, Ontario, and Saskatchewan.
        <br />
        As a registered EMD, we have to abide by all applicable securities
        regulations.
      </CommonText>
    </>
  );
};

export default RiskManagement;
