import { Box, Container, Flex, Link } from "@radix-ui/themes";
import CommonSubText from "components/common/sub-text";
import { RENDER_RDI_AS_PDF } from "components/main-router";
import { t } from "i18next";
import { DOC_PATHS } from "lib/enums/docs";
import { FLEX_DIR_MD, GAP, PAD_SM, STYLE_COLOR } from "lib/enums/radix-layout";
import { AppRoute } from "lib/enums/router";
import { useNavigate } from "react-router-dom";

interface NavButton {
  i18Key: string;
  onClick: () => void;
}

const Disclaimer = () => {
  const navigate = useNavigate();

  const NAV_BUTTONS: NavButton[] = [
    {
      i18Key: "investor-services",
      onClick: () => {
        navigate(`/${AppRoute.InvestorServices}`);
        window.scrollTo(0, 0);
      },
    },
    {
      i18Key: "institutions",
      onClick: () => {
        navigate(`/${AppRoute.InstitutionServices}`);
        window.scrollTo(0, 0);
      },
    },
    {
      i18Key: "contact-support",
      onClick: () => {
        navigate(`/${AppRoute.Contact}`);
        window.scrollTo(0, 0);
      },
    },
    {
      i18Key: "terms-of-use",
      onClick: () => {
        navigate(`/${AppRoute.Terms}`);
        window.scrollTo(0, 0);
      },
    },
    {
      i18Key: "relationship-disclosure",
      onClick: () => {
        if (RENDER_RDI_AS_PDF) {
          window.open(DOC_PATHS.RDI);
        } else {
          navigate(`/${AppRoute.Relationship}`);
          window.scrollTo(0, 0);
        }
      },
    },
    {
      i18Key: "privacy-policy",
      onClick: () => {
        navigate(`/${AppRoute.Privacy}`);
        window.scrollTo(0, 0);
      },
    },
  ];

  const renderButton = (b: NavButton) => (
    <Link
      key={`header-${b.i18Key}`}
      style={{ color: "white", whiteSpace: "nowrap" }}
      onClick={() => b.onClick()}
    >
      {t(b.i18Key)}
    </Link>
  );

  return (
    <Container
      size="3"
      style={{
        backgroundColor: STYLE_COLOR.ACCENT,
        color: "white",
      }}
    >
      <Flex
        direction={FLEX_DIR_MD}
        pt={PAD_SM.pv}
        pb={PAD_SM.pv}
        pl={PAD_SM.ph}
        pr={PAD_SM.ph}
        gap={GAP.sm}
      >
        <Flex direction="column" gap={GAP.xs}>
          <div>
            <img
              alt="icon"
              src={`${window.origin}/icon-light.svg`}
              style={{
                height: "32px",
              }}
            />
          </div>

          <CommonSubText>
            <p>{t("disclaimer-1")}</p>
            <p>{t("disclaimer-2")}</p>
            <p>{t("disclaimer-3")}</p>
            <p>{t("copyright-x", { x: new Date().getFullYear() })}</p>
          </CommonSubText>
        </Flex>
        <Box width="min-content">
          <Flex direction="column">
            {NAV_BUTTONS.map((b) => renderButton(b))}
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};

export default Disclaimer;
