import { AppRoute } from "lib/enums/router";
import { Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "./about";
import Contact from "./contact";
import InstitutionServices from "./institution-services";
import InvestorServices from "./investor-services";
import Landing from "./landing";
import MortgageInvestments from "./mortgage-investments";
import PrivacyPolicy from "./privacy";
import RealEstateFunds from "./real-estate-funds";
import RelationshipDisclosure from "./relationship";
import SpecialOpportunities from "./special-opportunities";
import TermsOfUse from "./terms";

export const RENDER_RDI_AS_PDF = true;

const MainRouter = () => {
  return (
    <Routes>
      <Route path={AppRoute.Root} element={<Landing />} />

      <Route path={AppRoute.About} element={<AboutUs />} />
      <Route path={AppRoute.InvestorServices} element={<InvestorServices />} />
      <Route
        path={AppRoute.MortgageInvestments}
        element={<MortgageInvestments />}
      />
      <Route
        path={AppRoute.SpecialOpportunities}
        element={<SpecialOpportunities />}
      />
      <Route path={AppRoute.RealEstateFunds} element={<RealEstateFunds />} />
      <Route path={AppRoute.Contact} element={<Contact />} />
      <Route path={AppRoute.Privacy} element={<PrivacyPolicy />} />
      {!RENDER_RDI_AS_PDF && (
        <Route
          path={AppRoute.Relationship}
          element={<RelationshipDisclosure />}
        />
      )}
      <Route
        path={AppRoute.InstitutionServices}
        element={<InstitutionServices />}
      />
      <Route path={AppRoute.Terms} element={<TermsOfUse />} />

      <Route path="*" element={<Navigate to={AppRoute.Root} />} />
    </Routes>
  );
};

export default MainRouter;
