import { Container, Flex } from "@radix-ui/themes";
import CollageWrapper from "components/common/collage-wrapper";
import FlexWrapper from "components/common/flex-wrapper";
import CommonHeader from "components/common/header";
import CommonSubHeader from "components/common/sub-header";
import CommonText from "components/common/text";
import CommonTitle from "components/common/title";
import Connect from "components/connect";
import { SEPARATOR } from "components/landing";
import Opportunities from "components/opportunities";
import { GAP } from "lib/enums/radix-layout";

const SpecialOpportunities = () => {
  return (
    <>
      <div className="soft-header">
        <Container size="3">
          <FlexWrapper>
            <CommonTitle align="center">Special opportunities</CommonTitle>

            <CommonText align="center">
              At BridgeMe Securities, we provide exclusive access to curated
              investment opportunities inprivate credit, private equity, and
              venture capital across North America and Europe. Our opportunities
              cater to a range of investment goals and risk appetites to unlock
              diversification options for investors.
            </CommonText>
          </FlexWrapper>
        </Container>
      </div>

      <Container size="3">
        <CollageWrapper>
          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/special-opportunities/prod_privateEquity_collage.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>
              Understanding Our Approach to Private Equity, Private Debt, and
              Venture Capital Investing
            </CommonHeader>

            <CommonSubHeader>
              Investing in special opportunities could provide substantial
              capital growth and our knowledgeable team at BridgeMe Securities
              can help you navigate the complexities of these investment
              strategies.
            </CommonSubHeader>

            <CommonText>
              Our team will ensure that you are well equipped to make informed
              decisions through our due diligence, including assessing the
              business model, financial health, market conditions, and broader
              economic factors that could impact the investment. It is critical
              to evaluate the potential for growth and the associated risks to
              determine the alignment with your investment objectives. BridgeMe
              Securities ensures that all offerings meet our high standards.
            </CommonText>
          </Flex>
        </CollageWrapper>

        {SEPARATOR}

        <CollageWrapper>
          <Flex direction="column" gap={GAP.sm}>
            <CommonHeader>
              Nuances of Private Equity, Private Debt, and Venture Capital
              Investing
            </CommonHeader>

            <CommonText>
              <p>
                Each investment type within private equity, private debt, and
                venture capital presents unique characteristics and
                considerations:
              </p>
              <ul>
                <li>
                  Private Equity, involving a direct ownership in private
                  companies, offering potential for significant returns and
                  strategic value creation;
                </li>
                <li>
                  Private Credit, providing a more predictable income stream,
                  typically through lending to private companies or individuals;
                  and
                </li>
                <li>
                  Venture Capital, targeting early-stage companies with high
                  growth potential, representing both a high-risk and
                  high-reward opportunity.
                </li>
              </ul>
            </CommonText>
          </Flex>

          <Flex justify="center">
            <img
              className="graphic-lg"
              src="/images/special-opportunities/prod_mortgage_collage.png"
            />
          </Flex>
        </CollageWrapper>

        {SEPARATOR}

        <FlexWrapper>
          <Opportunities />
        </FlexWrapper>
      </Container>

      <div className="soft-header">
        <Container size="2">
          <Connect />
        </Container>
      </div>
    </>
  );
};

export default SpecialOpportunities;
