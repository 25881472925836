import { Grid } from "@radix-ui/themes";
import { GAP, PAD_LG } from "lib/enums/radix-layout";
import { ReactNode } from "react";

const CollageWrapper = (props: { children: ReactNode }) => (
  <Grid
    pt={PAD_LG.pv}
    pb={PAD_LG.pv}
    pl={PAD_LG.ph}
    pr={PAD_LG.ph}
    columns={{
      initial: "1",
      md: "2",
    }}
    gap={GAP.md}
  >
    {props.children}
  </Grid>
);

export default CollageWrapper;
