import { Box, Grid } from "@radix-ui/themes";
import FlexWrapper from "components/common/flex-wrapper";
import CommonText from "components/common/text";
import CommonTitle from "components/common/title";
import { GAP } from "lib/enums/radix-layout";
import { ReactNode } from "react";
import ConnectForm from "./form";

const DEFAULT_MSG =
  "If you are interested in learning more about how BridgeMe Securities can assist you in optimizing your investment's returns, our team will work with you to achieve your investment goals. Contact us through this form and one of our team members will get in touch.";
const Connect = (props: { children?: ReactNode }) => {
  return (
    <FlexWrapper>
      <CommonTitle align="center">Connect with us</CommonTitle>

      <Grid
        gap={GAP.md}
        columns={{
          initial: "1",
          md: "2",
        }}
      >
        <Box className="show-lt-md">
          <CommonText>{props.children ?? DEFAULT_MSG}</CommonText>
        </Box>
        <ConnectForm />
        <Box className="show-gt-md">
          <CommonText>{props.children ?? DEFAULT_MSG}</CommonText>
        </Box>
      </Grid>
    </FlexWrapper>
  );
};

export default Connect;
