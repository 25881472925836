import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { Box, Button, Container, DropdownMenu, Flex } from "@radix-ui/themes";
import { t } from "i18next";
import { AppRoute } from "lib/enums/router";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "translations/i18n";

const ENABLE_LANGUAGE = false;

interface NavButton {
  i18Key: string;
  active: boolean;
  onClick?: () => void;
  menuItems?: NavButton[];
}

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(i18n.language);

  const pathname = location.pathname.split("/").slice(-1)[0] as AppRoute;

  const NAV_BUTTONS: NavButton[] = [
    {
      i18Key: "about",
      active: pathname === AppRoute.About,
      onClick: () => navigate(`/${AppRoute.About}`),
    },
    {
      i18Key: "investor-services",
      active: [
        AppRoute.InvestorServices,
        AppRoute.MortgageInvestments,
        AppRoute.RealEstateFunds,
      ].includes(pathname),

      menuItems: [
        {
          i18Key: "main",
          active: pathname === AppRoute.InvestorServices,
          onClick: () => navigate(`/${AppRoute.InvestorServices}`),
        },
        {
          i18Key: "real-estate-funds",
          active: pathname === AppRoute.RealEstateFunds,
          onClick: () => navigate(`/${AppRoute.RealEstateFunds}`),
        },
        {
          i18Key: "mortgage-investments",
          active: pathname === AppRoute.MortgageInvestments,
          onClick: () => navigate(`/${AppRoute.MortgageInvestments}`),
        },
        {
          i18Key: "special-opportunities",
          active: pathname === AppRoute.SpecialOpportunities,
          onClick: () => navigate(`/${AppRoute.SpecialOpportunities}`),
        },
      ],
    },
    {
      i18Key: "institutions",
      active: pathname === AppRoute.InstitutionServices,
      onClick: () => navigate(`/${AppRoute.InstitutionServices}`),
    },
    {
      i18Key: "contact",
      active: pathname === AppRoute.Contact,
      onClick: () => navigate(`/${AppRoute.Contact}`),
    },
  ];

  const renderMenuButton = (
    button: JSX.Element,
    menuItems: NavButton[],
    iMenu: number
  ) => {
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>{button}</DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {menuItems.map((subButton, iSubMenu) => (
            <DropdownMenu.Item
              key={`menu-${iMenu}-item-${iSubMenu}`}
              className={subButton.active ? "font-bold" : undefined}
              onClick={subButton.onClick}
            >
              {t(subButton.i18Key)}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  };

  const renderButton = (button: NavButton, index: number) => {
    const trigger = (
      <Button
        key={`header-${button.i18Key}`}
        size="3"
        variant="soft"
        radius="none"
        style={{
          borderBottom: button.active ? "4px solid" : "4px solid rgba(0,0,0,0)",
        }}
        onClick={button.onClick}
      >
        {t(button.i18Key)}
      </Button>
    );

    if (!button.menuItems) {
      return trigger;
    }

    return renderMenuButton(trigger, button.menuItems, index);
  };

  const langButton: NavButton = {
    i18Key: language === "en" ? "FR" : "EN",
    active: false,
    onClick: () => {
      const nextLanguage = (() => {
        switch (language) {
          case "en":
            return "fr";

          default:
            return "en";
        }
      })();

      console.debug(`Changing language to ${nextLanguage}`);

      setLanguage(nextLanguage);
      i18n.changeLanguage(nextLanguage);
      window.location.reload();
    },
  };

  return (
    <Container size="3" p="4">
      <Flex
        justify="between"
        mt={{
          initial: "0",
          md: "4",
        }}
        mb={{
          initial: "0",
          md: "4",
        }}
      >
        <img
          alt="logo"
          src={`${window.origin}/logo.svg`}
          className="show-gt-md clickable"
          style={{
            height: "50px",
          }}
          onClick={() => navigate(AppRoute.Root)}
        />

        <img
          alt="icon"
          src={`${window.origin}/icon-dark.svg`}
          className="show-lt-md clickable"
          style={{
            height: "25px",
          }}
          onClick={() => navigate(AppRoute.Root)}
        />

        <Box className="show-gt-md">
          <Flex direction="row" gap="1">
            {NAV_BUTTONS.map((b, i) => renderButton(b, i))}
            {ENABLE_LANGUAGE && renderButton(langButton, NAV_BUTTONS.length)}
          </Flex>
        </Box>

        <Box className="show-lt-md">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button variant="soft">
                <HamburgerMenuIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {NAV_BUTTONS.map((button, iMenu) => {
                const trigger = (
                  <DropdownMenu.Item
                    key={`header-menu-${iMenu}`}
                    className={button.active ? "font-bold" : undefined}
                    onClick={button.onClick}
                  >
                    {t(button.i18Key)}
                  </DropdownMenu.Item>
                );

                if (!button.menuItems) {
                  return trigger;
                }

                return renderMenuButton(trigger, button.menuItems, iMenu);
              })}

              {ENABLE_LANGUAGE && (
                <DropdownMenu.Item onClick={langButton.onClick}>
                  {t(langButton.i18Key)}
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Box>
      </Flex>
    </Container>
  );
};

export default Header;
