import { Heading } from "@radix-ui/themes";
import FlexWrapper from "components/common/flex-wrapper";
import CommonTitle from "components/common/title";
import { t } from "i18next";
import { FONT_SIZE } from "lib/enums/radix-layout";
import "./establish.scss";

const Establish = () => {
  return (
    <div id="Establish" className="soft-header">
      <div id="Title">
        <FlexWrapper>
          <CommonTitle>
            {t("establish-line-1")}
            <br />
            {t("establish-line-2")}
          </CommonTitle>

          <Heading
            className="show-gt-md"
            size={FONT_SIZE.subheader}
            weight="light"
          >
            Navigate with confidence:
            <br />
            Unlocking exclusive access to private market opportunities that meet
            your objectives.
          </Heading>
        </FlexWrapper>
      </div>
      <div
        id="Image"
        style={{ backgroundImage: "url('/images/landing/meeting.png')" }}
      ></div>
    </div>
  );
};

export default Establish;
