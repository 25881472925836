import ReCAPTCHA from "react-google-recaptcha";

// NOTE: https://www.google.com/recaptcha/admin/create
// use classic v2 keys
// server secret key 6LcCUy4pAAAAAM3wvHyG4G6dRDeMhoGzhMyswjBA

interface Props {
  onChange: (token: string | null) => void;
}

const ReCAPTCHAWrapper = (props: Props) => {
  return (
    <ReCAPTCHA
      sitekey="6LcCUy4pAAAAAJr2oVGFSHQLm15GPcPZSy62gVsr"
      onChange={props.onChange}
    />
  );
};

export default ReCAPTCHAWrapper;
