import { Container } from "@radix-ui/themes";
import { EMAILS, PHONES } from "lib/enums/urls";
import Markdown from "react-markdown";

const CONTENT = `## Introduction

BridgeMe Securities Inc. ("**BridgeMe**", the "**firm**", "**we**" or "**us**") has various obligations under the Personal Information Protection and Electronic Documents Act (Canada) ("**PIPEDA**") when handling personal information during the course if its business activities. This privacy policy is designed to inform clients and employees (collectively referred to as "**Persons**") when and how BridgeMe will collect, use and disclose their personal information.

## Scope of Policy

This policy is designed to address the 10 privacy principles referred to in PIPEDA. This policy may not cover all possible circumstances for the collection, use and disclosure of personal information. When that is the case, the collection, use and disclosure of personal information will be addressed on a case by case basis with all relevant parties being informed.

## Articles

### 1. Accountability

BridgeMe will be responsible for personal information in its possession, including information that has been transferred to a third party for processing.

BridgeMe's Chief Compliance Officer ("**CCO**") oversees the firm's compliance with PIPEDA in respect to the firm collecting, using and disclosing personal information. BridgeMe's CCO is accessible both within the organization and to clients to address issues relating to privacy and the firm's information disclosure policies. Any individual who wishes to discuss BridgeMe's privacy policy should contact the CCO at [${PHONES.COMPLIANCE}](tel:+${PHONES.COMPLIANCE_LINK}) or by email at [${EMAILS.COMPLIANCE}](mailto:${EMAILS.COMPLIANCE}).

Any complaint in respect to the firm's privacy policy will be investigated and where a complaint is found to be justified, BridgeMe will take appropriate measures, including amending its policies and procedures, when necessary.

### 2. Identifying purposes

BridgeMe will identify and document its purposes before collecting and using Persons' personal information. The firm will not use or disclose this information for any other purpose other than those for which it was collected.

BridgeMe may collect, use and/or disclose personal information about Persons from time to time. For example, BridgeMe may collect information about Persons such as their name, mailing address, telephone number, e-mail address, when they register to become a client or start their employment at BridgeMe.

Personal information collected by BridgeMe is used for one or more of the following purposes:

- to verify a Person's identity;
- in order to operate a Person's account(s) with the firm;
- to provide a Person with information respecting BridgeMe's services;
- to improve our services;
- to properly discharge BridgeMe's regulatory responsibilities such as conducting suitability assessments;
- to properly discharge BridgeMe's obligations under federal anti-money laundering and suppression of terrorism legislation; 
- to administer payroll and conduct various other administrative duties associated with being an employee of BridgeMe, when applicable.

The account opening documentation used by BridgeMe provides its clients with notice of the specific purposes for the collection of the clients' personal information, at or before the time of collection, and, by signing same, clients will have consented to such collection and use. 

### 3. Limiting use, disclosure, and retention

BridgeMe does not sell, rent or lease its customer lists to any third-party. However, the firm engages third-party service providers, most notably legal professionals, auditors, accountants and computer systems support. In the event, your information is provided to these service providers, the firm will require them to protect your information in a manner that is consistent with this Privacy Policy and practices. Personal information given to the firm or any of its service providers may also be accessible to governmental and/or regulatory authorities in accordance with applicable laws and regulations.

If BridgeMe sells the firm or enters into an acquisition or merger agreement with another company, BridgeMe may release the information it has about Persons to prospective purchasers. BridgeMe will require any prospective purchaser to protect the
information provided consistent with the firm's privacy policies and practices.

### 4. Consent

Generally, a Persons' knowledge and consent are required to collect, use or disclose personal information. Exceptions to this principle are provided for in PIPEDA and other relevant regulations and legislation.

By submitting personal information to BridgeMe, a Person agrees that BridgeMe may collect, use and disclose such personal information as described in, and in accordance with this Policy, and as otherwise permitted or required by law.

A Person may refuse or withdraw its consent to certain of the identified purposes in this Policy by contacting BridgeMe's CCO. If a Person refuses or withdraws its consent to certain purposes, we may not be able to establish or maintain a relationship with the Person or provide the Person with services or information that may be of value to the Person, especially if this information is required by the Ontario Securities Commission (the "**OSC**") or other regulatory or governmental bodies.

### 5. Limiting collection

BridgeMe will only collect personal information that is required to meet identified purposes. BridgeMe will only use fair and lawful means in order to collect this information.

BridgeMe will limit the personal information collected from Persons to the information necessary for the firm to conduct business in compliance with applicable laws and regulations, to be able to contact clients in an efficient manner, and to be in a position to properly provide services to clients. Unnecessary information will not be collected, used, disclosed or retained.

BridgeMe will collect personal information to fulfill its KYC obligations under applicable securities laws. This includes personal information to allow the firm to assess and understand a client's personal circumstances (e.g. contact information, age, marital and family situation, occupation and planned retirement), financial situation (e.g. annual income and net worth), investment goals and objectives, investment horizon, investment knowledge and experience and risk profile. For legal entities such as businesses, trusts, partnerships or estates, BridgeMe will also collect information about the legal structure of the entity and information about the individuals authorized to act on the entity's behalf. Where necessary BridgeMe will obtain information in respect to the beneficial owners of these entities.

### 6. Limiting use, disclosure, and retention

BridgeMe will only use or disclose personal information for purposes identified to the Person unless further consent is obtained. Client information on file will be kept for the standard period of seven years from the final closing of the client file, or the date the last service was provided to the client.

Disclosure of a Person's information will only occur in accordance with your consent or due to a legal obligation incumbent upon the firm. Your consent will be obtained at the time of collection of the personal information or in advance of any new
uses that may arise.

### 7. Accuracy

You are entitled to have complete and accurate information on file with BridgeMe. You may verify your personal information and if necessary, request in writing, an amendment to your personal information, by contacting the CCO.

Every effort will be made by the firm to maintain correct data in regard to a Person's personal information and from time to time clients may be asked to verify that information.

### 8. Safeguards

BridgeMe holds the security of your information in the highest regard. BridgeMe and the CCO will implement policies and procedures to ensure security of all forms of documents and information relevant to client privacy. BridgeMe will guard against loss and theft, as well as unauthorized access, disclosure, copying, use and modification, regardless of the format in which it is held.

Security measures will encompass physical, technological and organizational security. Security measures include but are not limited to electronic security software and hardware or digital files; properly secured paper files and alarm systems.

### 9. Openness

BridgeMe and the CCO will be open and transparent with the collection, use and disclosure of your personal information. In the event of a breach of privacy in which personal information may have been accessed or obtained by a third party in an unauthorized manner, you are entitled to know:

- If and how you have been affected;
- What was the date of the incident?
- When was the incident discovered?
- How was it discovered?
- What was the location of the incident?
- What was the cause of the incident?

### 10. Individual access

Clients may request, in writing, to access their personal information maintained by BridgeMe. All requests relating to individual access can be referred to the CCO.

You have the right to know if BridgeMe has any information about you. You have the right to access this information. BridgeMe will inform the client whether the firm holds personal information and provide an account of the use that has been made of this information, as well as identify any third parties to which the information has been disclosed. The client has the right to have their personal information corrected if they demonstrate the inaccuracy or incompleteness of their personal information.

### 11. Challenging compliance

The client has the right to challenge BridgeMe's compliance with any aspect of PIPEDA. Compliance challenges can be referred to the CCO, who can be contacted at [${PHONES.COMPLIANCE}](tel:+${PHONES.COMPLIANCE_LINK}) or at [${EMAILS.COMPLIANCE}](mailto:${EMAILS.COMPLIANCE}), or to the OPC at [www.priv.gc.ca](http://www.priv.gc.ca). Clients may express any concerns to the firm at no additional cost to the client. All client concerns shall be documented and maintained in a written (and electronic) file. BridgeMe will use commercially reasonable efforts to promptly determine and rectify
the problem.

### 12. Updating the Privacy Policy

BridgeMe will occasionally update this policy to reflect client feedback and/or regulatory changes.

Last Updated: 2022-04-01`;

const PrivacyPolicy = () => {
  return (
    <Container size="2" pb="8">
      <Markdown>{CONTENT}</Markdown>
    </Container>
  );
};

export default PrivacyPolicy;
