import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as en from "./en/translation.json";
import * as fr from "./fr/translation.json";

const detector = new LanguageDetector();
detector.init({
  order: ["localStorage"],
  lookupLocalStorage: "i18nextLng",
  cacheUserLanguage: true,
});

i18next.use(detector).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  fallbackLng: "en",
});

export default i18next;
